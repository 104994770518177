import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';
import { useHistory } from 'react-router-dom';
import 'styles/header.scss';
import importImage from 'utils/ImportImage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, Button, Menu, MenuItem } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Client from 'client/Client';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

/**
 * Page Header
 * based on material-ui BreadCrumbs
 * Unknown props are simply ignored
 * @component
 * @see {@link https://material-ui.com/api/breadcrumbs/  BreadCrumbs API}
 */
const MyHomeHeader = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [logOutMutation, logOutData] = useMutation(Client.LOGOUT);
  const [updateUsersMutation] = useMutation(Client.UPDATE_USER_LANGUAGE);
  const { t, i18n } = useTranslation();
  let websiteUrl = store.reCommerceLink;

  if (store.returnUrl) {
    websiteUrl =
      store.returnUrl.indexOf('http') === 0
        ? store.returnUrl
        : `${window.location.protocol}//${store.returnUrl}`;
  }
  const logOut = () => {
    if (document.querySelector('.white-loader')) {
      document.querySelector('.white-loader').style.display = 'flex!important';
    }
    logOutMutation().catch(() => {
      store.setSnackbarError(true, t('generic.call-error'));
      if (document.querySelector('.white-loader')) {
        document.querySelector('.white-loader').style.display =
          'none!important';
      }
    });
  };

  const changeLanguage = (language, locale) => {
    const { id } = store.loggedUser;
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    store.setLoggedUser({
      ...store.loggedUser,
      language,
      locale,
    });
    const languageToReturn = i18n.language;
    i18n.changeLanguage(language + (locale ? '-' + locale : ''));
    updateUsersMutation(
      Client.UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS(
        id,
        store.project_id,
        language,
        locale,
        !store.isVendor() && store.loggedUser.client
      )
    )
      .then(() => {
        store.setSnackbar(true, t('header.change-language-success'));
      })
      .catch(e => {
        store.setLoggedUser({
          ...store.loggedUser,
          language: oldLanguage,
          locale: oldLocale,
        });
        store.setSnackbarError(true, t('generic.call-error'));
        i18n.changeLanguage(languageToReturn);
        console.error(e);
      });
  };

  const goHome = () => {
    store.closeMenuMobile();
    history.push('/');
  };

  React.useEffect(() => {
    if (logOutData && logOutData.called && !logOutData.loading) {
      store.logOutUser(() => {
        window.location.href = websiteUrl;
      });
    }
  }, [logOutData && logOutData.called && !logOutData.loading]);
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const logoImage = importImage('logo-header.svg', store);
  const logoutImage = importImage('logout-icon.svg', store);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="header-wrapper background-color-1">
      <div className="center-header">
        <img
          className="image-header pointer"
          alt="logo"
          src={logoImage}
          onClick={goHome}
        />
      </div>
      <div className="right-header">
        <div className="header-language-select">
          <Button
            aria-controls="menu"
            className="language-selector go-to-website"
            disableRipple={true}
            onClick={handleClick}
          >
            {t('language.current')}
            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
          </Button>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{
              paper: 'no-border container-' + store.projectName,
            }}
          >
            <div>
              {store.languages.map(language => {
                const trueLanguage = language
                  ? language.split('-')[0]
                  : language;
                const locale = language ? language.split('-')[1] : null;
                return (
                  <MenuItem
                    key={'languages-' + language}
                    onClick={() => {
                      changeLanguage(trueLanguage, locale);
                    }}
                    selected={
                      t('language.current') ===
                      t('header.languages.' + language)
                    }
                  >
                    {t('header.languages.' + language, {
                      defaultValue: t('header.languages.' + trueLanguage, {
                        defaultValue: language,
                      }),
                    })}
                  </MenuItem>
                );
              })}
            </div>
          </Menu>
        </div>
        <span
          className="logout-button"
          onClick={
            store.videocallMode
              ? null
              : () => {
                  window.location.href = websiteUrl;
                }
          }
        >
          {!matchesPhone ? t('header.to-website') : <HomeIcon />}
        </span>
        <span
          className="logout-button"
          onClick={store.videocallMode ? null : logOut}
        >
          {!matchesPhone ? (
            <span className="logout-text">{t('header.logout')}</span>
          ) : null}
          <img src={logoutImage} alt="logout" />
        </span>
      </div>
    </div>
  );
});

MyHomeHeader.propTypes = {
  /** title: page title */
  title: PropTypes.string,
  /** goBack: boolean. If true, current page is a subPath. TODO: change this prop name */
  goBack: PropTypes.bool,
};

MyHomeHeader.defaultProps = {
  title: '',
  goBack: false,
};

export default MyHomeHeader;
