export const defaultInputProps = {
    classes: {
        root: "input-default",
        disabled: "disabled-wrapped-input",
        underline: "always-solid",
        input: "ellipsis"
    }
};

export const defaultInputAutocompleteLabelProps = {
    classes: {
        root: "smaller-font medium",
        shrink: "shrink-down",
        disabled: "disabled-wrapped-input"
    }
};

export const defaultInputLabelProps = {
    classes: {
        root: "small-label-font smaller-font medium",
        shrink: "shrink-down",
        disabled: "disabled-wrapped-input"
        }
};