import React from "react";
import PropTypes from 'prop-types';
import Fade from "@material-ui/core/Fade";
import Snackbar from '@material-ui/core/Snackbar';

/**
 * Default snackbar
 * based on material-ui snackbar
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/snackbar/ Snackbar API}
 */
const MyHomeSnackbar = (props) => {

    const {
        autoHideDuration,
        TransitionComponent,
        open,
        onClose,
        anchorOrigin,
        children,
        ...rest
    } = props;

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            TransitionComponent={TransitionComponent}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
            {...rest}
        >
            {children}
        </Snackbar>
    );
}

MyHomeSnackbar.propTypes = {
    /** anchorOrigin: position of the snackbar */
    anchorOrigin: PropTypes.object,
    /** TransitionComponent: a material ui transition component */
    TransitionComponent: PropTypes.elementType,
    /** open: if true, element is open */
    open: PropTypes.bool,
    /** onClose */
    onClose: PropTypes.func,
    /**autoHideDuration: snackbars hides after x ms, set to null to disable */
    autoHideDuration: PropTypes.number,
    /** children: modal content */
    children: PropTypes.node
}

MyHomeSnackbar.defaultProps = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,
    open: true,
    onClose: () => {},
    autoHideDuration: 5000
}

export default MyHomeSnackbar;