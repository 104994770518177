import React from 'react';
//import Home from "pages/Home";

//import Dashboard from "images/assets/dashboard.svg";

const ListaPreventivi = React.lazy(() => import('pages/ListaPreventivi'));
const ConfermaUtenza = React.lazy(() => import('pages/ConfermaUtenza'));
const CambiaPassword = React.lazy(() => import('pages/CambiaPassword'));
const SalesOnlineListaConfigurazioni = React.lazy(() =>
  import('components/salesOnline/SalesOnlineListaConfigurazioni')
);
export const HomeRoute = {
  id: 'home',
  order: 0,
  exact: true,
  to: '/',
  title: 'I miei preventivi',
  navbarTitle: 'I miei preventivi',
  icon: '',
  navbar: true,
  goBack: false,
  component: ListaPreventivi,
  salesOnlineComponent: SalesOnlineListaConfigurazioni,
};

export const ConfermaRoute = {
  id: 'conferma',
  order: 1,
  exact: true,
  to: '/confirm',
  title: 'Conferma utenza',
  navbarTitle: 'Conferma utenza',
  icon: '',
  navbar: true,
  goBack: true,
  component: ConfermaUtenza,
  skipLogin: true,
};

export const CambiaPasswordRoute = {
  id: 'cambiaPassword',
  order: 2,
  exact: true,
  to: '/changePassword',
  title: 'Cambia Password',
  navbarTitle: 'Cambia password',
  icon: '',
  navbar: true,
  goBack: true,
  component: CambiaPassword,
  skipLogin: true,
};

const RouteConsts = [HomeRoute, ConfermaRoute, CambiaPasswordRoute];
export default RouteConsts;
