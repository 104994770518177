/**
 * gets actual host
 * @todo remove this utility
 */

const CalculateUrl = (oldTool, newTool, store, extra, toolProd) => {
  let finalHostName = '';
  let hostname = window.location.hostname.replace('www.', '');
  if (hostname === store.hostKey.replace('www.', '')) {
    finalHostName =
      hostname + '/' + (toolProd || newTool) + (extra ? extra : '');
  } else {
    if (hostname.indexOf('localhost') >= 0) {
      //devo puntare a biz-tecma-dev1 con relativo hostname
      if (extra) {
        finalHostName = `${newTool}-biz-tecma-dev1.tecmasolutions.com${extra}hostname=${store.hostKey}`;
      } else {
        finalHostName = `${newTool}-biz-tecma-dev1.tecmasolutions.com/?hostname=${store.hostKey}`;
      }
    } else if (
      hostname.indexOf('biz-tecma') >= 0 ||
      hostname.indexOf('-demo.tecmasolutions.com') >= 0
    ) {
      const newHostName = hostname.replace(oldTool, newTool);
      //devo puntare a biz-tecma-dev1 con relativo hostname
      if (extra) {
        finalHostName = `${newHostName}${extra}hostname=${store.hostKey}`;
      } else {
        finalHostName = `${newHostName}/?hostname=${store.hostKey}`;
      }
    } else {
      finalHostName =
        `${store.hostKey.replace('www', toolProd || newTool)}` +
        (extra ? extra : '');
    }
  }
  return finalHostName;
};

export default CalculateUrl;
