/**
 * validates a phone number i.e. checks if the given string is compliant to an phone format (+ numbers)
 * @param {string} phone phone to validate
 */
const ValidatePhone = phone => {
  return (
    phone && /^[+]{0,1}[0-9]{1,4}[\s0-9]*$/g.test(String(phone).toLowerCase())
  );
};

export default ValidatePhone;
