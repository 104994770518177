import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import MyHomeInput from 'components/common/MyHomeInput';
import { useMutation } from '@apollo/client';
import Client from 'client/Client';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import ValidateEmail from 'utils/ValidateEmail';
import MyHomeSimpleModal from 'components/common/MyHomeSimpleModal';
import MyHomeLoader from 'components/common/MyHomeLoader';
import 'styles/login.scss';
import importImage from 'utils/ImportImage';
import Loader from 'react-loader-spinner';
import MyHomeSnackbarFeedback from 'components/common/MyHomeSnackbarFeedback';
import MyHomeSnackbarError from 'components/common/MyHomeSnackbarError';
import CookiesUtils from 'utils/CookieUtils';
import CalculateUrl from 'utils/CalculateUrl';
import { useTranslation } from '@tecma/i18n';
import { Button, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToolLink from 'utils/ToolLink';

/**
 * Pagina di login
 * @component
 */

/* const keyupEvent = (event) => {
  let button = document.getElementById('login-button');
  let changeButton = document.querySelector('.change-password-button-container>.change-password-button');
  if (changeButton) {
    button = changeButton;
  }
  // Number 13 is the "Enter" key on the keyboard
  if (button && event.keyCode === 13 && !button.disabled) {
    button.click();
  }
} */

const Login = observer(() => {
  const store = useStore();
  const [retry, setRetry] = React.useState(false);
  const [doLogin, loginData] = useMutation(Client.LOGIN);
  const [askChangePassword] = useMutation(Client.ASK_CHANGE_PASSWORD);
  const [retrievePassword, setRetrievePassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [retrievePasswordLoading, setRetrievePasswordLoading] =
    React.useState(false);

  const doneCalling = loginData && loginData.called && !loginData.loading;

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggedInStuff = data => {
    store.logOutUser(() => {
      CookiesUtils.setClientId(data.user.client);
      window.location.replace(ToolLink('myhome', store));
    });
  };

  React.useEffect(() => {
    if (doneCalling && loginData.data && loginData.data.loginByProject) {
      if (loginData.data.loginByProject.user.client) {
        loggedInStuff(loginData.data.loginByProject);
      } else {
        store.setErrorModal(t('login.no-client'));
      }
    }
  });

  if (
    store.myhomeConfig &&
    store.myhomeConfig.salesOnline &&
    window.location.hostname.indexOf('localhost') < 0
  ) {
    if (!store.loggedUser || !store.loggedUser.client) {
      let baseUrl =
        window.location.protocol +
        '//' +
        CalculateUrl(
          'myhome',
          'salesonline',
          store,
          '/login?',
          'floorplanning'
        );
      store.logOutUser(() => {
        window.location.replace(baseUrl);
      });
    } else {
      window.location.replace('');
    }
    return <></>;
  }

  if (
    loginData &&
    loginData.called &&
    (loginData.loading ||
      (doneCalling &&
        loginData.data &&
        loginData.data.loginByProject &&
        loginData.data.loginByProject.user.client))
  )
    return <MyHomeLoader z loading />;

  if (
    doneCalling &&
    (!loginData.data || !loginData.data.loginByProject) &&
    !store.errorModal &&
    !retry
  ) {
    store.setErrorModal(t('login.wrong-password'));
  }

  const validInput = store.clientEmail && ValidateEmail(store.clientEmail);

  const backgroundImage = importImage('background-login.jpg', store);
  const logoImage = importImage('logo-login.svg', store);

  if (!store.projectName) {
    return <></>;
  }
  return (
    <div
      className={`login-container container-${store.projectName} videochat-client-mouse-enabled videochat-client-keyboard-enabled`}
    >
      <MyHomeSnackbarFeedback />
      <MyHomeSnackbarError />
      <MyHomeSimpleModal
        open={!!store.errorModal}
        title={t('login.error-modal-title')}
        dialogClasses={{
          paper: 'modal-custom-container background-color-1',
          container: 'videochat-client-mouse-enabled',
        }}
        onClose={() => {
          store.setErrorModal(null);
          setRetry(true);
        }}
        disableActions
      >
        {store.errorModal}
      </MyHomeSimpleModal>
      <MyHomeSimpleModal
        open={retrievePassword}
        dialogClasses={{
          paper:
            'modal-custom-container background-color-1 title-center click-always-enabled',
        }}
        onClose={() => {
          setRetrievePassword(false);
        }}
        title={
          <h1 className="login-text text-color-1">{t('login.insert-email')}</h1>
        }
        disableActions
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            setRetrievePasswordLoading(true);
            askChangePassword(
              Client.ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS(
                store.project_id,
                email
              )
            )
              .then(res => {
                setRetrievePasswordLoading(false);
                if (res.data.askChangePassword.error) {
                  switch (res.data.askChangePassword.error) {
                    default: {
                      store.setSnackbarError(true, t('generic.call-error'));
                      break;
                    }
                  }
                } else {
                  store.setSnackbar(true, t('login.ask-change-success'));
                  setRetrievePassword(false);
                }
              })
              .catch(() => {
                store.setSnackbarError(true, t('generic.call-error'));
                setRetrievePasswordLoading(false);
              });
          }}
        >
          <MyHomeInput
            variant="standard"
            placeholder={t('generic.email-placeholder')}
            type="email"
            extraClass="login-margin-bottom"
            defaultInput
            value={email}
            required
            name={'ask-change-for-mail' + Math.random()}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <br />
          <br />
          <div className="login-button-container change-password-button-container">
            {retrievePasswordLoading ? (
              <Loader
                type="RevolvingDot"
                color="#00BFFF"
                height={100}
                width={100}
              />
            ) : (
              <input
                type="submit"
                id="login-button"
                className="change-password-button"
                value={t('login.request-password')}
              />
            )}
          </div>
        </form>
      </MyHomeSimpleModal>
      <div className="login-header background-color-1">
        <div className="logo-container">
          <img className="logo pointer" alt="logo" src={logoImage} />
        </div>
      </div>
      <div className="language-select-container-public">
        <Button
          aria-controls="menu"
          className="language-selector go-to-website"
          disableRipple={true}
          onClick={handleClick}
        >
          {t('language.current')}
          <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
        </Button>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{
            paper: 'no-border container-' + store.projectName,
          }}
        >
          <div>
            {store.languages.map(language => {
              return (
                <MenuItem
                  key={'languages-' + language}
                  onClick={() => {
                    i18n.changeLanguage(language);
                  }}
                  selected={
                    t('language.current') === t('header.languages.' + language)
                  }
                >
                  {t('header.languages.' + language, {
                    defaultValue: t(
                      'header.languages.' + language
                        ? language.split('-')[0]
                        : language,
                      {
                        defaultValue: language,
                      }
                    ),
                  })}
                </MenuItem>
              );
            })}
          </div>
        </Menu>
      </div>
      <Hidden xsDown>
        <div className="login-background">
          <img
            alt="login"
            className="login-background-image"
            src={backgroundImage}
          />
        </div>
      </Hidden>
      <form
        className="login-form background-color-2"
        onSubmit={e => {
          e.preventDefault();
          setRetry(false);
          doLogin({
            variables: {
              input: {
                email: store.clientEmail,
                password: store.clientPassword,
                project_id: store.project_id,
              },
            },
          }).catch(() => {
            store.setErrorModal(t('login.wrong-password'));
          });
        }}
      >
        <h1 className="login-text text-color-1">{t('login.title')}</h1>
        <MyHomeInput
          variant="standard"
          placeholder={t('generic.email-placeholder')}
          type="email"
          extraClass="login-margin-bottom"
          defaultInput
          value={store.clientEmail}
          error={!(store.clientEmail === '' || validInput)}
          helperText={
            !(store.clientEmail === '' || validInput)
              ? t('login.invalid-email-format')
              : ''
          }
          onChange={e => {
            store.updateClientEmail(e.target.value);
          }}
        />
        <MyHomeInput
          variant="standard"
          placeholder={t('generic.password-placeholder')}
          type="password"
          extraClass="login-margin-bottom"
          defaultInput
          value={store.clientPassword}
          error={!(store.clientPassword === '' || validInput)}
          onChange={e => {
            store.updateClientPassword(e.target.value);
          }}
        />
        <div className="login-button-container">
          <input
            className="custom-button"
            id="login-button"
            type="submit"
            value={t('login.submit-text')}
          />
        </div>
        {/*
          <div className="login-password-recover">
            <span style={{ textDecoration: "underline" }}>
              Hai dimenticato la password?
            </span>
          </div>
        */}
        <div className="login-password-recover">
          <a href={store.contactForm}>{t('login.no-credentials')}</a>
        </div>
        <div className="login-password-recover">
          <a
            className="change-password"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setRetrievePassword(true);
            }}
          >
            {t('login.forgot-password')}
          </a>
        </div>
        <div className="special-bottom" />
      </form>
    </div>
  );
});

export default Login;
