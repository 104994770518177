import Cookies from 'js-cookie';
import psl from 'psl';

const clientKey = 'clientId';
const tokenKey = 'jwt';
const refreshTokenKey = 'refreshToken';
const tokenExpireKey = 'expiresIn';

const clientId = 'clientId';
const vendorId = 'vendorId';
const refreshToken = 'refreshToken';
const expiresIn = 'expiresIn';
const jwt = 'jwt';

const goBackKey = 'salesOnlineGoBack';

let domain = 'localhost';
if (window.location.hostname !== 'localhost') {
  const pslUrl = psl.parse(window.location.hostname);
  domain = pslUrl.domain;
}

const cookieOptions = {
  expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
  domain,
};

export function set(key, value, options) {
  const finalOptions = options
    ? options
    : options === 'false'
    ? {}
    : cookieOptions;
  Cookies.set(key, value, finalOptions);
}

export function get(key) {
  return Cookies.get(key);
}

export function remove(key) {
  return Cookies.remove(key);
}

export function getClientId() {
  return Cookies.get(clientKey);
}

export function setClientId(clientId) {
  return Cookies.set(clientKey, clientId, cookieOptions);
}

export function removeClientId() {
  Cookies.remove(clientKey, { domain });
}

export function getToken() {
  return Cookies.get(tokenKey);
}

export function setToken(token) {
  Cookies.set(tokenKey, token, cookieOptions);
}

export function removeToken() {
  Cookies.remove(tokenKey, { domain });
}

export function setRefreshToken(token) {
  Cookies.set(refreshTokenKey, token, cookieOptions);
}

export function getRefreshToken() {
  return Cookies.get(refreshTokenKey);
}

export function removeRefreshToken() {
  Cookies.remove(refreshTokenKey, { domain });
}

export function setTokenExpire(expiresIn) {
  Cookies.set(tokenExpireKey, expiresIn, cookieOptions);
}

export function getTokenExpire() {
  return Cookies.get(tokenExpireKey);
}

export function removeTokenExpire() {
  Cookies.remove(tokenExpireKey, { domain });
}

export function setGoBack(goBack) {
  Cookies.set(goBackKey, goBack, cookieOptions);
}

export function getGoBack() {
  return Cookies.get(goBackKey);
}

export function removeGoBack() {
  Cookies.remove(goBackKey, { domain });
}

export function logOut() {
  Cookies.remove(clientKey, { domain });
  Cookies.remove(tokenKey, { domain });
  Cookies.remove(refreshTokenKey, { domain });
  Cookies.remove(tokenExpireKey, { domain });

  Cookies.remove(clientId, { domain });
  Cookies.remove(vendorId, { domain });
  Cookies.remove(jwt, { domain });
  Cookies.remove(refreshToken, { domain });
  Cookies.remove(expiresIn, { domain });
}

const CookiesUtils = {
  set,
  get,
  remove,
  getClientId,
  setClientId,
  removeClientId,
  getToken,
  setToken,
  removeToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  getTokenExpire,
  setTokenExpire,
  removeTokenExpire,
  getGoBack,
  setGoBack,
  removeGoBack,
  logOut,
};
export default CookiesUtils;
