import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const AlertDialog = (props) => {
  const {
    open,
    title,
    content,
    isConfirm,
    handleClose,
    handleConfirm,
    confirmText,
    closeText,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isConfirm ? (
          <Button
            onClick={handleClose}
            color='primary'
            className='videochat-client-mouse-enabled'
            classes={{ label: 'videochat-client-mouse-enabled' }}
          >
            {closeText}
          </Button>
        ) : (
          ''
        )}
        <Button
          onClick={handleConfirm}
          color='primary'
          autoFocus
          className='videochat-client-mouse-enabled'
          classes={{ label: 'videochat-client-mouse-enabled' }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
