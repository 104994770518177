import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import Store from 'store/store.js';
import { StoreProvider } from 'store/storeUtils.js';
import { create } from 'mobx-persist';
import 'styles/main.scss';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MyHomeMUITheme } from 'styles/MyHomeMUITheme';
import { ApolloProvider } from '@apollo/client';
import API from 'client/GraphqlClient';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ServiceWorkerWrapper from 'components/common/ServiceWorkerWrapper';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '@tecma/ui';
import importImage from 'utils/ImportImage';

const theme = createMuiTheme(MyHomeMUITheme);

const hydrate = create({});

let store = new Store();

hydrate('store_myhome', store)
  .catch(e => {
    console.log(e);
    delete localStorage['store_myhome'];
    store = new Store();
    hydrate('store_myhome', store);
  })
  .finally(() => {
    ReactDOM.render(
      <ErrorBoundary
        FallbackComponent={() => {
          return (
            <ErrorPage
              logo={importImage('logo-header.svg', store)}
              onClick={() => {
                window.location = '/';
              }}
            />
          );
        }}
      >
        <StoreProvider value={store}>
          <ApolloProvider client={API(store)}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <App />
                <ServiceWorkerWrapper />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </ApolloProvider>
        </StoreProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  });
