import React from "react";
import MyHomeSnackbar from "components/common/MyHomeSnackbar";
import { useStore } from 'store/storeUtils.js';
import { observer } from "mobx-react";

const MyHomeSnackbarError = observer(() => {

    const store = useStore();

    return (
        <MyHomeSnackbar open={store.snackbarError} onClose={() => store.setSnackbarError(false)}>
            <div className="snackbar-body-error">
                {store.snackbarMessage}
            </div>
        </MyHomeSnackbar>
    );
});

export default MyHomeSnackbarError;