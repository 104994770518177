import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { muteUnmuteAudio, muteUnmuteVideo } from './utils/webcamUtils';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import { ButtonBase, Grid } from '@material-ui/core';
import clsx from 'clsx';

const VideoChatController = ({
  terminateCall,
  handleChangeSize,
  handleFullscreen,
  infoMessage,
}) => {
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isCallTerminated, setIsCallTerminated] = useState(false);

  return (
    <>
      <Grid container className="video-controller ">
        <Grid xs item className="videocall-control click-always-enabled">
          <ButtonBase
            onClick={() => {
              muteUnmuteAudio();
              setIsAudioMuted(isAudioMuted => !isAudioMuted);
            }}
            classes={{ root: 'videochat-no-remote-click' }}
          >
            {isAudioMuted ? <MicOffIcon /> : <MicIcon />}
          </ButtonBase>
        </Grid>
        <Grid item xs className="videocall-control click-always-enabled">
          <ButtonBase
            onClick={() => {
              muteUnmuteVideo();
              setIsVideoMuted(isVideoMuted => !isVideoMuted);
            }}
            classes={{ root: 'videochat-no-remote-click' }}
          >
            {isVideoMuted ? <VideocamOffIcon /> : <VideocamIcon />}
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs
          className="videocall-control videochat-no-remote-click click-always-enabled"
        >
          <ButtonBase
            onClick={() => {
              setIsCallTerminated(isCallTerminated => !isCallTerminated);
              terminateCall();
            }}
            classes={{ root: 'videochat-no-remote-click' }}
          >
            {isCallTerminated ? <CallEndIcon /> : <CallEndIcon />}
          </ButtonBase>
        </Grid>

        <Grid
          item
          xs
          className="videocall-control videochat-no-remote-click videocall-client-hidden"
        >
          <ButtonBase
            onClick={handleChangeSize}
            classes={{ root: 'videochat-no-remote-click' }}
          >
            <PhotoSizeSelectSmallIcon />
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs
          className="videocall-control videochat-no-remote-click videocall-client-hidden"
        >
          <ButtonBase
            onClick={handleFullscreen}
            className="videocall-fullscreen videocall-client-disabled"
          >
            <FullscreenExitIcon />
          </ButtonBase>
        </Grid>
      </Grid>
      <div className={clsx('video-info-msg', !infoMessage && 'hidden')}>
        {infoMessage}
      </div>
    </>
  );
};

export default VideoChatController;
