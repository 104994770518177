import React from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import { defaultInputProps, defaultInputLabelProps } from "constants/InputConstants.jsx";
import "styles/input.scss";

/**
 * Default input
 * based on material-ui TextField
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/text-field/ Text Field API}
 */
const MyHomeInput = (props) => {

    const {
        defaultInput,
        extraClass,
        label,
        required,
        disabled,
        variant,
        value,
        extraInputLabelProps,
        extraInputProps,
        ...rest
    } = props;

    const mergedInputLabelProps = Object.assign({}, extraInputLabelProps, defaultInputLabelProps);
    const mergedInputProps = Object.assign({}, extraInputProps, defaultInputProps);

    return (
        <>
            <TextField
                variant={variant}
                label={label}
                required={required}
                disabled={disabled}
                value={value}
                InputLabelProps={defaultInput ? props.extraInputLabelProps : mergedInputLabelProps}
                InputProps={defaultInput ? props.extraInputProps : mergedInputProps}
                className={`input-text ${extraClass}`}
                {...rest}
            />
        </>
    );
}

MyHomeInput.defaultProps = {
    variant: "standard",
    extraClass: "",
    label: "",
    required: false,
    defaultInput: false,
    disabled: false
}

/** prop types */
MyHomeInput.propTypes = {
    /** defaultInput: removes custom css overrides */
    defaultInput: PropTypes.bool,
    /** extraInputLabelProps: additional props for the label */
    extraInputLabelProps: PropTypes.object,
    /** InputProps: additional props for the underlying Input component */
    extraInputProps: PropTypes.object,
    /** extraClass: additional className */
    extraClass: PropTypes.string,
    /** value: string or number */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** label: for the input */
    label: PropTypes.string,
    /** variant: string, based on material-ui input variants. Must be one of ["standard", "filled", "outlined"] */
    variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
    /** required: boolean, required input */
    required: PropTypes.bool,
    /** disabled: boolean, disabled input */
    disabled: PropTypes.bool
}

export default MyHomeInput;