import colors from './colors.scss';
import variables from './variables.scss';

export const MyHomeMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontWeight: variables.LIGHT,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTypography: {
      caption: {
        fontWeight: variables.MEDIUM,
      },
    },
    MuiPaper: {
      root: {
        color: colors.DEFAULT_FONT_COLOR + '!important',
      },
      elevation2: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      elevation8: {
        boxShadow: '0px 10px 15px rgba(0,0,0,0.16)',
      },
    },
    MuiButton: {
      root: {
        height: 45,
        color: colors.DEFAULT_FONT_COLOR,
        '&:hover': {
          '&.sales-online-login-button': {
            background: '#FBDAB9!important',
          },
          '&.sales-online-step-5-download-button': {
            background: '#FBDAB9!important',
          },
          '&.sales-online-login-button-bonifico': {
            background: '#FBDAB9!important',
          },
          '&.sales-online-modal-card-cro-button': {
            background: '#FBDAB9!important',
          },
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    MuiInputBase: {
      root: {
        height: 45,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none!important',
      },
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: '0px!important',
        borderTopWidth: '0px!important',
      },
      body: {
        color: colors.DEFAULT_FONT_COLOR + '!important',
      },
      head: {
        fontWeight: variables.LIGHT,
        color: colors.GREY_TEXT + '!important',
        borderBottomWidth: '1px!important',
      },
    },
    MuiTablePagination: {
      spacer: {
        display: 'none',
      },
      caption: {
        display: 'block',
      },
      select: {
        fontWeight: variables.MEDIUM,
      },
      toolbar: {
        justifyContent: 'space-between',
        width: '96%!important',
        marginLeft: '2%',
        marginRight: '2%',
      },
    },
    MuiRadio: {
      root: {
        color: '#9F9E9D',
      },
      colorSecondary: {
        '&$checked': {
          color: '#F5942E',
        },
      },
    },
    MuiSelect: {
      icon: {
        background: '#705466',
        height: '100%',
        top: 0,
        color: '#FFFFFF',
        width: '40px',
      },
    },
    MuiAlert: {
      standardWarning: {
        color: '#FF0000 !important',
        backgroundColor: '#FFE4E4',
        marginBottom: 20,
        padding: 'unset !important',
        borderRadius: 'unset',
      },
    },
  },
  palette: {
    primary: {
      main: colors.COLOR_PRIMARY,
    },
    secondary: {
      main: colors.COLOR_SECONDARY,
    },
  },
};
