import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/common/MyHomeHeader';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import SalesOnlineHeader from 'components/salesOnline/SalesOnlineHeader';
/**
 * Template generico della pagina
 * Contiene un Header, il contenuto della pagina circondato da un suspense
 * @component
 * @see MyHomeHeader
 * @see MyHomeSuspense
 */
const PageTemplate = observer(props => {
  const { BodyComponent, title, goBack, skipLogin, ...rest } = props;

  const store = useStore();

  if (!store.projectName) {
    return <></>;
  }

  if (skipLogin) {
    return <BodyComponent title={title} {...rest} />;
  }

  return (
    <div
      className={`main-container background-color-1 container-${
        store.projectName
      } ${store.isVendor() ? 'logged-as-vendor' : 'not-logged-as-vendor'} ${
        store.clientData && store.clientData.myhome_version
          ? 'client-version-' + store.clientData.myhome_version
          : ''
      }`}
    >
      {store.myhomeConfig && store.myhomeConfig.salesOnline ? (
        <SalesOnlineHeader title={title} goBack={goBack} />
      ) : (
        <Header title={title} goBack={goBack} />
      )}
      <div className="body-wrapper">
        <BodyComponent title={title} {...rest} />
      </div>
    </div>
  );
});

PageTemplate.propTypes = {
  /** BodyComponent: required content */
  BodyComponent: PropTypes.elementType.isRequired,
  /** title: string. MyHomeHeader prop */
  title: PropTypes.string,
  /** goBack: boolean. MyHomeHeader prop */
  goBack: PropTypes.bool,
};

PageTemplate.defaultProps = {
  title: '',
  goBack: false,
};

export default PageTemplate;
