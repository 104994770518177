import React, { useEffect } from "react";
import PropTypes from 'prop-types';

const VideoChatComponent = (props) => {
    const { socket, roomId } = props

    useEffect(() => {
        if (roomId && socket) {
        }
    }, []);

    return (
        <>
            <video autoPlay className="remote-video" id="remote-video"></video>
            <video autoPlay muted className="local-video" id="local-video"></video>
        </>
    );

}

VideoChatComponent.propTypes = {

    socket: PropTypes.any.isRequired,
    roomId: PropTypes.string.isRequired

}


export default VideoChatComponent;