import { gql } from '@apollo/client';

const CUSTOM_QUOTE_DATA_TO_RETRIEVE = `
boxes{
  box
  price
  meters
  floor
}
basements{
  basement
  price
  meters
  floor
}
parkingSpace_cars{
  parkingSpace_car
  price
  meters
  floor
}
parkingSpace_bykes{
  parkingSpace_byke
  price
  meters
  floor
}
features{
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  status
  selectedBy{
    appartment
    client{
      id
    }
    status
    expiryOn
  }
}
promos{
  promo
  name
  info
  percentage
  type
  price
}
payments{
  name
  endDate
  price
  percentage
  daysForPayment
}
expenses{
  name
  price
  percentage
}
iva{
  percentage
  name
}
promoPrice
totalPrice
fixedRate{
  percentage
  value
}
variableRate{
  percentage
  value
}
rate
automatic
`;

const APPARTMENT_DATA_TO_RETRIEVE = `
      id
      name
      price
      status
      floor
      available
      updatedOn
      visits
      visitsByDay{
        day
        visits
      }
      extraInfo
      superfici
      plan {
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        SuperficieCommerciale
        SuperficieLoggia
        bathroom
        bedroom
        typology {
          id
          name
        }
      }
      building {
        name
      }
      sides {
        name
        pass
        staircase
        building {
          id
          name
        }
      }
      standardQuote{
        ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
      }
	    interests
      selectedBy{
        client {
          id
        }
        expiryOn
        status
      }
      extraInfo
      willBeAvailable
      superfici
`;

const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      vendorLogo
      assetLogin
      hostKey
      assetKey
      pdf
      assetsUrl
      displayName
      pageTitles {
        followup
        tuning
      }
      proposalTemplate
      contactPhone
      contactEmail
      contactForm
      city
      logo
      myhomeConfig
      homeConfiguratorConfig
      enabledTools
      iban
      myhomeProDisabled
      projectUrl
      baseurl
      currency
      locale
`;

const CLIENT_DATA_TO_RETRIEVE = `
      reserved
      firstName
      lastName
      email
      tel
      birthdate
      city
      trattamento
      profilazione
      marketing
      myhome_version
`;

const QUOTE_DATA_TO_RETRIEVE = `
      id
      appartment {
        ${APPARTMENT_DATA_TO_RETRIEVE}
      }
      vendor
      quoteNumber
      createdOn
      customQuote {
        ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
      }
      status
      reserved
      proposals
      savedDocuments {
        name
        path
        lastModified
      }
      idDocuments {
        name
        type
        base64
      }
      secondProposer {
        email
      }
      favorite
      lastTransaction {
        status
        _id
      }
      selectedOwner
      customizations {
        configurationNumber
        pdfFileName
        createdOn
        status
      }
      availableForProposalRetry
      enableHCBtn
`;

const ENBABLED_CUSTOM = `
    enabled
`;

const DRAFT_PROPOSAL_DATA_TO_RETRIEVE = `
      id
      quote
      url
      updatedOn
      fields
`;

const USER_DATA_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  role
  client
  language
  locale
`;

const CUSTOMIZATION_DATA_TO_RETRIEVE = `
  configurationNumber
  price
  createdOn
  pdfFileName
  status
`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = hostname => {
  return {
    variables: {
      host: hostname,
      url: window.location.origin + window.location.pathname,
    },
  };
};

const GET_QUOTE = gql`
query getQuote($project_id: ID!, $id: ID!, $noBase64: Boolean, $client: ID) {
  getQuote(project_id: $project_id, id: $id, noBase64: $noBase64, client: $client){
    ${QUOTE_DATA_TO_RETRIEVE}
  }
}`;

const GET_QUOTE_DEFAULT_OPTIONS = (pId, id, noBase64, client) => {
  return {
    variables: {
      project_id: pId,
      id,
      noBase64,
      client,
    },
  };
};

const CAN_ADD_CUSTOMIZATIONS = gql`
query canAddCustomizations($project_id: ID!, $id: ID!) {
  canAddCustomizations(project_id: $project_id, id: $id){
    ${ENBABLED_CUSTOM}
  }
}`;

const CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };
};

const GET_QUOTES_BY_CLIENT = gql`
query getQuotesByClient($project_id: ID!, $client: ID!, $noBase64: Boolean, $futureLogic: Boolean) {
  getQuotesByClient(project_id: $project_id, client: $client, noBase64: $noBase64, futureLogic: $futureLogic){
    ${QUOTE_DATA_TO_RETRIEVE}
  }
}`;

const GET_QUOTES_BY_CLIENT_DEFAULT_OPTIONS = (
  pId,
  cId,
  noBase64,
  futureLogic
) => {
  return {
    variables: {
      project_id: pId,
      client: cId,
      noBase64,
      futureLogic,
    },
    fetchPolicy: 'no-cache',
  };
};

const DELETE_QUOTE = gql`
  mutation deleteQuotes($project_id: ID!, $id: ID!) {
    deleteQuotes(project_id: $project_id, id: $id) {
      id
      status
    }
  }
`;

const DELETE_QUOTE_DEFAULT_OPTIONS = (pId, quote) => {
  return {
    variables: {
      project_id: pId,
      id: quote.id,
    },
  };
};

const RESERVE_QUOTE = gql`
  mutation reserveAppartmentsByQuote(
    $project_id: ID!
    $quoteId: ID!
    $stripeId: String!
  ) {
    reserveAppartmentsByQuote(
      project_id: $project_id
      quoteId: $quoteId
      stripeId: $stripeId
    )
  }
`;

const RESERVE_QUOTE_DEFAULT_OPTIONS = (pId, quote, stripeId) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quote.id,
      stripeId,
    },
  };
};

const UPLOAD_ID_DOCUMENTS = gql`
  mutation uploadIdDocuments(
    $project_id: ID!
    $quoteId: ID!
    $clientId: ID!
    $idDocuments: [IdDocumentInput]!
  ) {
    uploadIdDocuments(
      project_id: $project_id
      quoteId: $quoteId
      clientId: $clientId
      idDocuments: $idDocuments
    )
  }
`;

const UPLOAD_ID_DOCUMENTS_DEFAULT_OPTIONS = (
  pId,
  quote,
  clientId,
  idDocuments
) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quote.id,
      clientId,
      idDocuments,
    },
  };
};

const SEND_SALES_ONLINE_PROPOSAL = gql`
  mutation sendSalesOnlineProposal(
    $project_id: ID!
    $quoteId: ID!
    $pdf: [SalesOnlineProposalInput!]!
    $documents: [DuplicateDocumentInput]
  ) {
    sendSalesOnlineProposal(
      project_id: $project_id
      quoteId: $quoteId
      pdf: $pdf
      documents: $documents
    )
  }
`;

const SEND_SALES_ONLINE_PROPOSAL_DEFAULT_OPTIONS = (
  pId,
  quote,
  pdf,
  documents
) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quote.id,
      pdf,
      documents,
    },
  };
};

const PRINT_PDF_FROM_HTML = gql`
  mutation printPdfFromHtml($project_id: ID!, $pdfHtml: [String]!) {
    printPdfFromHtml(project_id: $project_id, pdfHtml: $pdfHtml)
  }
`;

const PRINT_PDF_FROM_HTML_DEFAULT_OPTIONS = (project_id, pdfHtml) => {
  return {
    variables: {
      project_id,
      pdfHtml,
    },
  };
};

const SEND_PROPOSAL = gql`
  mutation sendProposal(
    $project_id: ID!
    $quoteId: ID!
    $proposal: [String]!
    $loginInput: LoginByProjectInput!
    $documents: [DuplicateDocumentInput]
    $secondProposerCode: String
    $extraInfo: JSON
    $otp: OTPValidate
  ) {
    sendProposal(
      project_id: $project_id
      quoteId: $quoteId
      proposal: $proposal
      loginInput: $loginInput
      documents: $documents
      secondProposerCode: $secondProposerCode
      extraInfo: $extraInfo
      otp: $otp
    )
  }
`;

const SEND_PROPOSAL_DEFAULT_OPTIONS = (
  pId,
  quote,
  proposal,
  email,
  password,
  documents,
  otpToken,
  otpValidationKey,
  secondProposerCode,
  extraInfo
) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quote.id,
      proposal,
      loginInput: {
        project_id: pId,
        email,
        password,
      },
      documents,
      otp: otpValidationKey
        ? { token: otpToken, validationKey: otpValidationKey.validationKey }
        : undefined,
      secondProposerCode,
      extraInfo,
    },
  };
};

const SEND_PROPOSAL_VENDOR_OPTIONS = (
  project_id,
  quote,
  proposal,
  documents,
  extraInfo
) => {
  return {
    variables: {
      project_id,
      quoteId: quote.id,
      proposal,
      loginInput: {
        project_id,
        email: 'fake@email.vendor',
        password: 'FakePassword',
      },
      documents,
      extraInfo,
    },
  };
};

const GET_CLIENT = gql`
query getClient($project_id: ID!, $id: ID!) {
  getClient(project_id: $project_id, id: $id){
    ${CLIENT_DATA_TO_RETRIEVE}
  }
}`;

const GET_CLIENT_DEFAULT_OPTIONS = (pId, clientId) => {
  return {
    variables: {
      project_id: pId,
      id: clientId,
    },
  };
};

const GET_CLIENT_DOCUMENTS = gql`
  query getClientDocument($project_id: ID!, $id: ID!, $noQuote: Boolean) {
    getClientDocument(project_id: $project_id, id: $id, noQuote: $noQuote)
  }
`;

const GET_CLIENT_DOCUMENTS_DEFAULT_OPTIONS = (pId, clientId) => {
  return {
    variables: {
      project_id: pId,
      id: clientId,
      noQuote: true,
    },
  };
};

const GET_CLIENT_DOCUMENTS_V2 = gql`
  query getClientDocumentsByClientV2($project_id: ID!, $client_id: ID!) {
    getClientDocumentsByClientV2(project_id: $project_id, client_id: $client_id)
  }
`;

const GET_CLIENT_DOCUMENTS_V2_DEFAULT_OPTIONS = (project_id, client_id) => {
  return {
    variables: {
      project_id,
      client_id,
    },
    fetchPolicy: 'no-cache',
  };
};

const UPLOAD_CLIENT_DOCUMENTS_V2 = gql`
  mutation uploadMultipleClientDocuments(
    $documents: [ClientDocumentInput]!
    $client_id: ID!
    $project_id: ID!
    $quote_ids: [ID]
  ) {
    uploadMultipleClientDocuments(
      documents: $documents
      client_id: $client_id
      project_id: $project_id
      quote_ids: $quote_ids
    )
  }
`;

const UPLOAD_CLIENT_DOCUMENTS_V2_DEFAULT_OPTIONS = (
  project_id,
  quote_ids = [],
  client_id,
  documents
) => {
  return {
    variables: {
      documents,
      client_id,
      project_id,
      quote_ids,
    },
  };
};

const ASSOCIATE_QUOTE_DOCUMENTS_V2 = gql`
  mutation associateQuoteDocuments(
    $document_ids: [ID]!
    $client_id: ID!
    $project_id: ID!
    $quote_id: ID!
  ) {
    associateQuoteDocuments(
      document_ids: $document_ids
      client_id: $client_id
      project_id: $project_id
      quote_id: $quote_id
    )
  }
`;

const ASSOCIATE_QUOTE_DOCUMENTS_V2_DEFAULT_OPTIONS = (
  project_id,
  quote_id,
  client_id,
  document_ids = []
) => {
  return {
    variables: {
      document_ids,
      client_id,
      project_id,
      quote_id,
    },
  };
};

const GET_POLICIES = gql`
  query getAllPolicies($project_id: ID!) {
    getAllPolicies(project_id: $project_id) {
      name
      text
    }
  }
`;

const GET_POLICIES_DEFAULT_OPTIONS = pId => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_PAYMENT_INTENT = gql`
  mutation getPaymentIntent($project_id: ID!, $input: PaymentIntentInput!) {
    getPaymentIntent(project_id: $project_id, input: $input) {
      client_secret
      status
    }
  }
`;

const GET_PAYMENT_INTENT_DEFAULT_OPTIONS = (pId, quoteId) => {
  return {
    variables: {
      project_id: pId,
      input: {
        quoteId,
      },
    },
  };
};

const GET_SEPA_PAYMENT_INTENT = gql`
  mutation getSEPAPaymentIntent($project_id: ID!, $input: PaymentIntentInput!) {
    getSEPAPaymentIntent(project_id: $project_id, input: $input) {
      client_secret
      status
    }
  }
`;

const GET_SEPA_PAYMENT_INTENT_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input,
    },
  };
};

const START_RESERVE_SESSION = gql`
  mutation startReserveSession($project_id: ID!, $input: ReserveSessionInput!) {
    startReserveSession(project_id: $project_id, input: $input) {
      error
      expiryOn
    }
  }
`;

const START_RESERVE_SESSION_DEFAULT_OPTIONS = (
  pId,
  quoteId,
  clientId,
  buying,
  refresh
) => {
  return {
    variables: {
      project_id: pId,
      input: {
        quoteId,
        clientId,
        buying,
        refresh,
      },
    },
  };
};

const CONFIRM_USER = gql`
  mutation confirmUser($project_id: ID!, $token: String!, $password: String!) {
    confirmUser(project_id: $project_id, token: $token, password: $password)
  }
`;

const CONFIRM_USER_DEFAULT_OPTIONS = (project_id, token, password) => {
  return {
    variables: {
      token,
      password,
      project_id,
    },
  };
};

const SEND_CRO = gql`
  mutation sendCro($project_id: ID!, $quoteId: ID!, $cro: String!) {
    sendCro(project_id: $project_id, quoteId: $quoteId, cro: $cro)
  }
`;

const SEND_CRO_DEFAULT_OPTIONS = (project_id, quoteId, cro) => {
  return {
    variables: {
      quoteId,
      cro,
      project_id,
    },
  };
};

const CONFIRM_SEPA_PAYMENT = gql`
  mutation confirmSEPAQuotePayment(
    $project_id: ID!
    $a: String!
    $b: String!
    $landing: Boolean
  ) {
    confirmSEPAQuotePayment(
      project_id: $project_id
      a: $a
      b: $b
      landing: $landing
    )
  }
`;

const CONFIRM_SEPA_PAYMENT_DEFAULT_OPTIONS = (project_id, a, b, landing) => {
  return { variables: { project_id, a, b, landing } };
};

const ASK_CHANGE_PASSWORD = gql`
  mutation askChangePassword($project_id: ID!, $email: String!) {
    askChangePassword(project_id: $project_id, email: $email)
  }
`;

const ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, email) => {
  return {
    variables: {
      email,
      project_id,
    },
  };
};

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $project_id: ID!
    $token: String!
    $password: String!
  ) {
    changePassword(project_id: $project_id, token: $token, password: $password)
  }
`;

const CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, token, password) => {
  return {
    variables: {
      token,
      password,
      project_id,
    },
  };
};

const GET_VENDOR_INFORMATIONS = gql`
  query getVendorInformation($vendorId: ID!, $project_id: ID!) {
    getVendorInformation(vendorId: $vendorId, project_id: $project_id)
  }
`;

const GET_VENDOR_INFORMATIONS_DEFAULT_OPTIONS = (project_id, vendorId) => {
  return {
    variables: {
      project_id,
      vendorId,
    },
  };
};

const EMAIL_SECOND_PROPOSER = gql`
  mutation emailSecondProposer(
    $project_id: ID!
    $quoteId: ID!
    $secondProposer: SecondClientInput!
    $skip: String
    $language: String
  ) {
    emailSecondProposer(
      project_id: $project_id
      quoteId: $quoteId
      secondProposer: $secondProposer
      skip: $skip
      language: $language
    )
  }
`;

const EMAIL_SECOND_PROPOSER_DEFAULT_OPTIONS = (
  project_id,
  quoteId,
  secondProposer,
  skip,
  language
) => {
  return {
    variables: {
      project_id,
      quoteId,
      secondProposer,
      skip,
      language,
    },
  };
};

const GENERATE_OTP = gql`
  mutation generateOTP($project_id: ID!, $input: OTPReceive!) {
    generateOTP(project_id: $project_id, input: $input) {
      validationKey
      expirationDate
    }
  }
`;

const GENERATE_OTP_DEFAULT_OPTIONS = (project_id, email, phone, language) => {
  return {
    variables: {
      project_id,
      input: {
        email,
        phone,
        language,
      },
    },
  };
};

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        ${USER_DATA_TO_RETRIEVE}
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      tokenType
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const REFRESH_TOKEN_DEFAULT_OPTIONS = (refreshToken, email) => {
  return {
    variables: {
      input: {
        refreshToken,
        email,
      },
    },
  };
};

const UPDATE_FAVORITE = gql`
  mutation updateFavoriteQuote(
    $project_id: ID!
    $quoteId: ID!
    $favorite: Boolean!
  ) {
    updateFavoriteQuote(
      project_id: $project_id
      quoteId: $quoteId
      favorite: $favorite
    ){
      ${QUOTE_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_FAVORITE_DEFAULT_OPTIONS = (pId, quote) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quote.id,
      favorite: !quote.favorite,
    },
  };
};

const GET_DRAFT_PROPOSAL_BY_QUOTE = gql`
  query getDraftProposalByQuote($project_id :ID!, $quote: ID!) {
      getDraftProposalByQuote(project_id: $project_id, quote: $quote) {
      ${DRAFT_PROPOSAL_DATA_TO_RETRIEVE}
      }
  }
`;

const GET_DRAFT_PROPOSAL_BY_QUOTE_OPTIONS = (project_id, quote) => {
  return {
    variables: {
      project_id,
      quote,
    },
  };
};

const GET_DRAFT_PROPOSAL = gql`
  query getDraftProposal($project_id :ID!, $id: ID!) {
    getDraftProposal(project_id: $project_id, id: $id) {
      ${DRAFT_PROPOSAL_DATA_TO_RETRIEVE}
      }
  }
`;

const GET_DRAFT_PROPOSAL_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
  };
};

const CREATE_DRAFT_PROPOSAL_MUTATION = gql`
mutation createDraftProposal($project_id :ID!, $draftProposalInput: DraftProposalInput!) {
  createDraftProposal(project_id: $project_id, input: $draftProposalInput) {
        ${DRAFT_PROPOSAL_DATA_TO_RETRIEVE}
        }
    }
`;

const CREATE_DRAFT_PROPOSAL_OPTIONS = (project_id, draftProposalInput) => {
  return {
    variables: {
      project_id,
      draftProposalInput,
    },
  };
};

const UPDATE_DRAFT_PROPOSAL_MUTATION = gql`
mutation updateDraftProposal($project_id :ID!, $draftProposalId: ID!, $draftProposalInput: DraftProposalInput!) {
  updateDraftProposal(project_id: $project_id, id: $draftProposalId, input: $draftProposalInput) {
        ${DRAFT_PROPOSAL_DATA_TO_RETRIEVE}
        }
    }
`;

const UPDATE_DRAFT_PROPOSAL_OPTIONS = (
  project_id,
  draftProposalId,
  draftProposalInput
) => {
  return {
    variables: {
      project_id,
      draftProposalId,
      draftProposalInput,
    },
  };
};

const DELETE_DRAFT_PROPOSAL_MUTATION = gql`
  mutation deleteDraftProposal($project_id: ID!, $draftProposalId: ID!) {
    deleteDraftProposal(project_id: $project_id, id: $draftProposalId)
  }
`;

const DELETE_DRAFT_PROPOSAL_OPTIONS = (project_id, draftProposalId) => {
  return {
    variables: {
      project_id,
      draftProposalId,
    },
  };
};

const SEND_SEPA = gql`
  mutation createSEPAQuotePayment(
    $project_id: ID!
    $shopTransactionInput: ShopTransactionInput!
  ) {
    createSEPAQuotePayment(
      project_id: $project_id
      input: $shopTransactionInput
    )
  }
`;

const SEND_SEPA_OPTIONS = (project_id, shopTransactionInput) => {
  return {
    variables: {
      project_id,
      shopTransactionInput,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: 'no-cache',
  };
};

const UPDATE_USER_LANGUAGE = gql`
  mutation updateUserLanguage( $id: ID!, $project_id:ID!, $language: String!, $locale: String, $client: ID) {
    updateUserLanguage( id: $id, project_id:$project_id, language: $language, locale: $locale, client: $client) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS = (
  id,
  project_id,
  language,
  locale,
  client
) => {
  const variables = { id, project_id, language };
  if (locale) variables.locale = locale;
  if (client) variables.client = client;
  return {
    variables,
    fetchPolicy: 'no-cache',
  };
};

const UPDATE_CUSTOMIZATION_STATUS = gql`
  mutation updateCustomizationStatus(
    $project_id: ID!
    $quoteId: ID!
    $configurationNumber: String!
    $status: String!
  ) {
    updateCustomizationStatus(
      project_id: $project_id
      quoteId: $quoteId
      configurationNumber: $configurationNumber
      status: $status
    ) {
      customization {
        ${CUSTOMIZATION_DATA_TO_RETRIEVE}
      }
      vendor
      appartment
      client
    }
  }
`;

const UPDATE_CUSTOMIZATION_STATUS_DEFAULT_OPTIONS = (
  project_id,
  quoteId,
  configurationNumber,
  status
) => ({
  variables: {
    project_id,
    quoteId,
    configurationNumber,
    status,
  },
});

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  GET_QUOTE,
  GET_QUOTE_DEFAULT_OPTIONS,
  CAN_ADD_CUSTOMIZATIONS,
  CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS,
  GET_QUOTES_BY_CLIENT,
  GET_QUOTES_BY_CLIENT_DEFAULT_OPTIONS,
  DELETE_QUOTE,
  DELETE_QUOTE_DEFAULT_OPTIONS,
  RESERVE_QUOTE,
  RESERVE_QUOTE_DEFAULT_OPTIONS,
  GET_CLIENT,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_POLICIES,
  GET_POLICIES_DEFAULT_OPTIONS,
  GET_PAYMENT_INTENT,
  GET_PAYMENT_INTENT_DEFAULT_OPTIONS,
  GET_SEPA_PAYMENT_INTENT,
  GET_SEPA_PAYMENT_INTENT_DEFAULT_OPTIONS,
  START_RESERVE_SESSION,
  START_RESERVE_SESSION_DEFAULT_OPTIONS,
  UPLOAD_ID_DOCUMENTS,
  UPLOAD_ID_DOCUMENTS_DEFAULT_OPTIONS,
  SEND_SALES_ONLINE_PROPOSAL,
  SEND_SALES_ONLINE_PROPOSAL_DEFAULT_OPTIONS,
  PRINT_PDF_FROM_HTML,
  PRINT_PDF_FROM_HTML_DEFAULT_OPTIONS,
  SEND_PROPOSAL,
  SEND_PROPOSAL_DEFAULT_OPTIONS,
  SEND_PROPOSAL_VENDOR_OPTIONS,
  CONFIRM_USER,
  CONFIRM_USER_DEFAULT_OPTIONS,
  SEND_CRO,
  SEND_CRO_DEFAULT_OPTIONS,
  CONFIRM_SEPA_PAYMENT,
  CONFIRM_SEPA_PAYMENT_DEFAULT_OPTIONS,
  GET_VENDOR_INFORMATIONS,
  GET_VENDOR_INFORMATIONS_DEFAULT_OPTIONS,
  ASK_CHANGE_PASSWORD,
  ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_DEFAULT_OPTIONS,
  EMAIL_SECOND_PROPOSER,
  EMAIL_SECOND_PROPOSER_DEFAULT_OPTIONS,
  GENERATE_OTP,
  GENERATE_OTP_DEFAULT_OPTIONS,
  GET_CLIENT_DOCUMENTS,
  GET_CLIENT_DOCUMENTS_DEFAULT_OPTIONS,
  GET_CLIENT_DOCUMENTS_V2,
  GET_CLIENT_DOCUMENTS_V2_DEFAULT_OPTIONS,
  UPLOAD_CLIENT_DOCUMENTS_V2,
  UPLOAD_CLIENT_DOCUMENTS_V2_DEFAULT_OPTIONS,
  ASSOCIATE_QUOTE_DOCUMENTS_V2,
  ASSOCIATE_QUOTE_DOCUMENTS_V2_DEFAULT_OPTIONS,
  UPDATE_FAVORITE,
  UPDATE_FAVORITE_DEFAULT_OPTIONS,
  GET_DRAFT_PROPOSAL_BY_QUOTE,
  GET_DRAFT_PROPOSAL_BY_QUOTE_OPTIONS,
  GET_DRAFT_PROPOSAL,
  GET_DRAFT_PROPOSAL_OPTIONS,
  CREATE_DRAFT_PROPOSAL_MUTATION,
  CREATE_DRAFT_PROPOSAL_OPTIONS,
  UPDATE_DRAFT_PROPOSAL_MUTATION,
  UPDATE_DRAFT_PROPOSAL_OPTIONS,
  DELETE_DRAFT_PROPOSAL_MUTATION,
  DELETE_DRAFT_PROPOSAL_OPTIONS,
  SEND_SEPA,
  SEND_SEPA_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  LOGOUT,
  UPDATE_USER_LANGUAGE,
  UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS,
  UPDATE_CUSTOMIZATION_STATUS,
  UPDATE_CUSTOMIZATION_STATUS_DEFAULT_OPTIONS,
};

export default Client;
