import React from "react";
import MyHomeSnackbar from "components/common/MyHomeSnackbar";
import { useStore } from 'store/storeUtils.js';
import { observer } from "mobx-react";

const MyHomeSnackbarFeedback = observer(() => {

    const store = useStore();

    return (
        <MyHomeSnackbar open={store.snackbarOpen} onClose={() => store.setSnackbar(false)}>
            <div className="snackbar-body">
                {store.snackbarMessage}
            </div>
        </MyHomeSnackbar>
    );
});

export default MyHomeSnackbarFeedback;