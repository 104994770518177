import React from 'react';
import { Route } from 'react-router-dom';
import MyHomeSnackbarFeedback from 'components/common/MyHomeSnackbarFeedback';
import MyHomeSnackbarError from 'components/common/MyHomeSnackbarError';
import { useStore } from 'store/storeUtils';
import MyHomeSuspense from 'components/common/MyHomeSuspense';

const PublicRoute = props => {
  const {
    exact,
    path,
    component,
    salesOnlineComponent,
    title,
    goBack,
    skipLogin,
  } = props;
  const store = useStore();
  return (
    <MyHomeSuspense>
      <MyHomeSnackbarFeedback />
      <MyHomeSnackbarError />
      <Route
        exact={exact}
        path={path}
        component={
          store.myhomeConfig &&
          store.myhomeConfig.salesOnline &&
          salesOnlineComponent
            ? salesOnlineComponent
            : component
        }
        title={title}
      />
    </MyHomeSuspense>
  );
};

export default PublicRoute;
