import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

/**
 * react suspense wrapper with default fallback
 * @component
 */
const MyHomeSuspense = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="loader-wait-suspended">
          <Loader
            type="RevolvingDot"
            color="#00BFFF"
            height={100}
            width={100}
          />
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
};

/** prop types */
MyHomeSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default MyHomeSuspense;
