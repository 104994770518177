import React from 'react';
import MyHomeSnackbar from "components/common/MyHomeSnackbar";
import MyHomeButton from "components/common/MyHomeButton";
import * as serviceWorker from 'serviceWorker';
import MyHomeLoader from 'components/common/MyHomeLoader';

const ServiceWorkerWrapper = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(null);
    const [showReloading, setShowReloading] = React.useState(false);

    const onSWUpdate = (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    React.useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
      if (waitingWorker) {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        waitingWorker.postMessage('SKIP_WAITING');
        setShowReloading(true);
      }
    };

    return (
        <MyHomeSnackbar
            open={showReload}
            message={!showReloading? "Una nuova versione è disponibile!" : <><div style={{height: '60px'}}></div></> }
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ zIndex: '9999999999999999' }}
            action={
                showReloading? 
                <MyHomeLoader loading={true} hasBackdrop={false} transparency /> :
                <MyHomeButton size="small" onClick={reloadPage}>
                    Aggiorna
                </MyHomeButton>
            }
        />
    );
}

export default ServiceWorkerWrapper;