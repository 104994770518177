import React from 'react';
import { Button } from '@material-ui/core';
import { useStore } from 'store/storeUtils';
import CookiesUtils from 'utils/CookieUtils';
import { useHistory } from 'react-router-dom';
import CalculateUrl from 'utils/CalculateUrl';

import importImage from 'utils/ImportImage';
import { observer } from 'mobx-react';

import Client from 'client/Client';
import { useMutation } from '@apollo/client';

const SalesOnlineHeader = observer(() => {
  const store = useStore();
  const Logo = importImage('logo.svg', store);
  const BackArrow = importImage('icon-freccia-indietro.svg', store);
  const Lock = importImage('icon-lock.svg', store);
  const NewConfig = importImage('icon-nuova-configurazione.svg', store);
  const FaqIcon = importImage('icon-faq.svg', store);
  const LogoIcon = importImage('icon-logo.svg', store);
  const faqUrl = 'https://theunits.it/faq.html';

  const websiteLink = store.isVendor()
    ? window.location.protocol +
      '//' +
      CalculateUrl('salesonline', 'neurosales', store)
    : store.projectUrl ?? store.reCommerceLink ?? `https://${store.hostKey}`;

  const handleClickTornaAlSito = () => {
    window.open(websiteLink, '_self');
  };

  const [logMutation, logOutData] = useMutation(Client.LOGOUT);

  if (logOutData && logOutData.called && !logOutData.loading) {
    store.logOutUser(() => {
      window.location.href = '/login';
    });
  }

  return (
    <div
      className={
        'sales-online-header-wrapper' +
        (store.selectedQuote
          ? ' sales-online-header-wrapper-selected-quote'
          : '')
      }
    >
      <div className="sales-online-logo-container">
        <img
          className="sales-online-logo"
          src={Logo}
          onClick={handleClickTornaAlSito}
        />
      </div>
      <div className="sales-online-header-options-wrapper">
        <Button
          className="sales-online-header-option-container"
          startIcon={<img style={{ width: '14px' }} src={NewConfig} />}
          onClick={() => {
            let baseUrl =
              window.location.protocol +
              '//' +
              CalculateUrl(
                'myhome',
                'salesonline',
                store,
                undefined,
                'floorplanning'
              );
            window.location.href = baseUrl;
          }}
        >
          <div className="sales-online-header-option-label">
            Nuova configurazione
          </div>
        </Button>
        <div className="sales-online-header-option-divider" />

        <Button
          className="sales-online-header-option-container"
          startIcon={<img style={{ width: '14px' }} src={BackArrow} />}
          onClick={() => {
            if (store.goBack) {
              store.goBack();
            } else window.history.back();
          }}
        >
          <div className="sales-online-header-option-label">Indietro</div>
        </Button>
        <div className="sales-online-header-option-divider" />
        <Button
          className="sales-online-header-option-container"
          startIcon={<img style={{ width: '14px' }} src={LogoIcon} />}
          onClick={handleClickTornaAlSito}
        >
          <div className="sales-online-header-option-label">
            Torna alla home
          </div>
        </Button>
        <div className="sales-online-header-option-divider-full-height" />
        <Button
          className="sales-online-header-option-container"
          startIcon={<img style={{ height: '14px' }} src={FaqIcon} />}
          onClick={() => {
            window.open(faqUrl, '_blank');
          }}
        >
          <div
            className="sales-online-header-option-label"
            style={{ color: '#CD942E' }}
          >
            Domande frequenti
          </div>
        </Button>
        <div className="sales-online-header-option-divider-full-height" />
        <Button
          className="sales-online-header-option-container"
          onClick={() => {
            logMutation();
          }}
          startIcon={<img style={{ width: '10px' }} src={Lock} />}
        >
          <div className="sales-online-header-option-label">Esci</div>
        </Button>
      </div>
    </div>
  );
});

export default SalesOnlineHeader;
