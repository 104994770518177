import React from 'react';
import { Route } from 'react-router-dom';
import PageTemplate from 'pages/PageTemplate';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import MyHomeSnackbarFeedback from 'components/common/MyHomeSnackbarFeedback';
import MyHomeSnackbarError from 'components/common/MyHomeSnackbarError';
import CalculateUrl from 'utils/CalculateUrl';
import { loadStripe } from '@stripe/stripe-js';
import MyHomeSuspense from 'components/common/MyHomeSuspense';
import { useTranslation } from '@tecma/i18n';

const getPaymentIntentStatus = (store, t) => {
  loadStripe(process.env.REACT_APP_STRIPE_KEY).then(stripe => {
    stripe
      .retrievePaymentIntent(store.clientSecret.paymentIntentSecret)
      .then(res => {
        const paymentIntent = res.paymentIntent;
        if (paymentIntent.status === 'requires_capture') {
          store.setClientSecret({ ...store.clientSecret, valid: true });
        } else {
          store.setSnackbarError(true, t('reserve.webhook-failed-payment'));
          store.setClientSecret(undefined);
        }
      })
      .catch(e => {
        console.log(e);
        store.setSnackbarError(true, t('reserve.webhook-failed-retrieve'));
      });
  });
};

const PrivateRoute = observer(props => {
  const store = useStore();

  const {
    exact,
    path,
    component,
    salesOnlineComponent,
    title,
    goBack,
    skipLogin,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const UrlQueryStrings = props.location.search;
  const queryValues = queryString.parse(UrlQueryStrings);
  if (store.clientSecret && !store.clientSecret.valid) {
    getPaymentIntentStatus(store, t);
  }
  if (queryValues.payment_intent_client_secret && queryValues.quote) {
    store.setClientSecret({
      paymentIntent: queryValues.payment_intent,
      paymentIntentSecret: queryValues.payment_intent_client_secret,
      quote: queryValues.quote,
    });
    history.replace('/');
  }
  if (queryValues.returnUrl) {
    store.setReturnUrl(
      queryString.parse(UrlQueryStrings, { decode: false }).returnUrl
    );
    history.replace('/');
  }
  if (
    store.myhomeConfig &&
    store.myhomeConfig.salesOnline &&
    skipLogin &&
    window.location.hostname.indexOf('localhost') < 0
  ) {
    let baseUrl =
      window.location.protocol +
      '//' +
      CalculateUrl('myhome', 'salesonline', store, '/login?', 'floorplanning');
    window.location.href = baseUrl;
  }

  const loading = store.loggedUser && store.loggedUser.loading;

  return (
    <>
      <MyHomeSnackbarFeedback />
      <MyHomeSnackbarError />
      <Route
        exact={exact}
        path={path}
        render={props => {
          if (loading || !store.baseUrl) {
            return <></>;
          }
          return (
            <MyHomeSuspense>
              <PageTemplate
                BodyComponent={
                  store.myhomeConfig &&
                  store.myhomeConfig.salesOnline &&
                  salesOnlineComponent
                    ? salesOnlineComponent
                    : component
                }
                title={title}
                goBack={goBack}
                skipLogin={skipLogin}
                {...props}
              />
            </MyHomeSuspense>
          );
        }}
      />
    </>
  );
});

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
