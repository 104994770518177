import Cookies from 'js-cookie';
import GetDomain from 'utils/GetDomain';

const cookieOptions = {
  domain: GetDomain(),
};

export const updateCallCookie = (roomId, isVendor, clientId) => {
  try {
    const options = {
      ...cookieOptions,
      expires: new Date(new Date().getTime() + 60 * 1000), // 30sec
    };

    const callCookieContent = {
      roomId,
      isVendor,
      clientId,
    };

    Cookies.set('videocall', JSON.stringify(callCookieContent), options);

    console.log('Call Cookie refreshed');
  } catch (e) {
    console.log(e);
  }
};

export const deleteCallCookie = () => {
  try {
    Cookies.remove('videocall', cookieOptions);

    console.log('Call Cookie deleted');
  } catch (e) {
    console.log(e);
  }
};

export const getCallCookie = () => {
  try {
    const videocallCookie = Cookies.get('videocall');
    if (videocallCookie) {
      return JSON.parse(Cookies.get('videocall'));
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};
