import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import RouteConsts from '../routes/Routes.jsx';
import ToolLink from 'utils/ToolLink.jsx';

const API = store => {
  const errorLink = onError(({ networkError, operation }) => {
    if (
      (networkError?.result?.errors[0]?.extensions?.code ===
        'UNAUTHENTICATED' ||
        networkError?.statusCode === 401) &&
      operation.operationName !== 'getUserByJWT'
    ) {
      let websiteUrl = ToolLink('myhome', store, {
        path: '/login',
      });
      if (store.returnUrl) {
        websiteUrl =
          store.returnUrl.indexOf('http') === 0
            ? store.returnUrl
            : `${window.location.protocol}//${store.returnUrl}`;
      }
      store.setReturnUrl(undefined);
      window.location.href = websiteUrl;
    }
  });

  const retryIfFunc = (error, _operation, ...rest) => {
    return !!error;
  };

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 60000,
      jitter: true,
    },
    attempts: {
      max: 2,
      retryIf: retryIfFunc,
    },
  });

  const httpLink = from([
    errorLink,
    retryLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    credentials: 'include',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default API;
