import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { useLazyQuery } from '@apollo/client';
import Client from 'client/Client';
import CookiesUtils from './CookieUtils';
import { toJS } from 'mobx';
import RouteConsts from 'routes/Routes';
import { useTranslation } from '@tecma/i18n';
import { useHistory } from 'react-router-dom';

const JwtChecker = () => {
  const store = useStore();
  const history = useHistory();

  const skipCondition = [
    '/login',
    ...RouteConsts.filter(r => r.skipLogin).map(r => r.to),
  ].find(
    route =>
      history.location.pathname === route ||
      history.location.pathname === route + '/'
  );
  const [loadUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, skipCondition)
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    const { returnUrl } = store;
    if (userInfo.called && !userInfo.loading) {
      if (userInfo.data && userInfo.data.getUserByJWT) {
        const newLoggedUserInfo = {
          ...userInfo.data.getUserByJWT,
          client: CookiesUtils.get('clientId'),
        };
        if (
          store.loggedUser &&
          store.loggedUser.client &&
          store.loggedUser.client !== newLoggedUserInfo.client
        ) {
          window.location.reload(true);
          return;
        }
        if (
          JSON.stringify(toJS(store.loggedUser)) !==
          JSON.stringify(newLoggedUserInfo)
        ) {
          store.setLoggedUser(newLoggedUserInfo);
        }
        const convertLanguage = lang => {
          if (lang === 'it') return 'it-IT';
          else if (lang === 'en') return 'en-GB';
          else return lang;
        };
        const userLanguage =
          newLoggedUserInfo.language &&
          convertLanguage(
            newLoggedUserInfo.language +
              (newLoggedUserInfo.locale ? '-' + newLoggedUserInfo.locale : '')
          );
        if (userLanguage && i18n.language !== userLanguage) {
          i18n.changeLanguage(userLanguage);
        }
        if (skipCondition) {
          return;
        }
        if (newLoggedUserInfo.role !== 'client' && !newLoggedUserInfo.client) {
          const url = store.logoutUrl;
          store.logOutUser(() => {
            window.location.href = url;
          });
        } else if (
          newLoggedUserInfo.role === 'client' &&
          !newLoggedUserInfo.client
        ) {
          store.logOutUser(() => {
            history.push('/login');
          });
        }
      } else {
        if (skipCondition) {
          return;
        }
        store.logOutUser(() => {
          if (returnUrl) {
            window.location.href =
              returnUrl.indexOf('http') === 0
                ? returnUrl
                : `${window.location.protocol}//${returnUrl}`;
          } else {
            history.push('/login');
          }
        });
      }
    }
  }, [userInfo, !userInfo.loading, userInfo.called, userInfo.data]);

  function handleVisibilityChange() {
    if (!document.hidden && !skipCondition) {
      store.setCheckingJwt(true);
      loadUserInfo();
    }
  }

  React.useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    if (!skipCondition) {
      store.setLoggedUser({ ...store.loggedUser, loading: true });
      handleVisibilityChange();
    }
    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return <></>;
};

export default JwtChecker;
