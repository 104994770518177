import React from 'react';
import PropTypes from 'prop-types';
import Portal from '@material-ui/core/Portal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MyHomeButton from 'components/common/MyHomeButton';
import 'styles/modal.scss';
import { useStore } from 'store/storeUtils';

/**
 * A default, simple error modal
 * based on material-ui Dialog
 * @component
 * @see {@link https://material-ui.com/api/dialog/ Dialog API}
 */
const MyHomeSimpleModal = props => {
  const {
    disablePrimary,
    title,
    children,
    onClose,
    dialogClasses,
    extraContentClasses,
    actionToDo,
    buttonLabel,
    cancelLabel,
    cancelAction,
    open,
    maxWidth,
    disableActions,
    portalClasses,
    hasCrossCloseButton,
    cancelClassName,
    actionClassName,
  } = props;

  const action = () => {
    actionToDo();
    onClose();
  };

  const cancel = () => {
    cancelAction();
    onClose();
  };

  const store = useStore();

  const projectDialogClasses = { ...dialogClasses };
  projectDialogClasses.paper = `${projectDialogClasses.paper} container-${store.projectName} ${portalClasses} videocall-stop-event-checking`;
  projectDialogClasses.container = `${projectDialogClasses.container} modal-container-${store.projectName}`;

  const handleDialogOnClick = () => {
    if (sessionStorage.getItem('videocallMode')) {
      // if (
      //   e.target &&
      //   e.target.classList &&
      //   e.target.classList.value.includes(projectDialogClasses.container)
      // ) {
      onClose();
      // }
    }
  };

  return (
    <Portal>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        classes={projectDialogClasses}
        onClose={onClose}
        onClick={handleDialogOnClick}
      >
        <DialogTitle
          classes={{
            root: 'modal-title light',
          }}
          disableTypography
        >
          {title}
        </DialogTitle>
        {hasCrossCloseButton && (
          <div onClick={cancel} className="modal-cross-button" />
        )}
        <DialogContent
          classes={{
            root: `modal-content ${extraContentClasses}`,
          }}
        >
          {children}
        </DialogContent>
        {!disableActions && (
          <DialogActions classes={{ root: 'modal-actions' }}>
            {cancelAction && (
              <MyHomeButton onClick={cancel} color="primary" className={cancelClassName}>
                {cancelLabel}
              </MyHomeButton>
            )}
            <MyHomeButton
              onClick={action}
              disabled={disablePrimary}
              color="primary"
              className={actionClassName}
            >
              {buttonLabel}
            </MyHomeButton>
          </DialogActions>
        )}
      </Dialog>
    </Portal>
  );
};

MyHomeSimpleModal.defaultProps = {
  extraContentClasses: '',
  maxWidth: 'sm',
  dialogClasses: {},
  title: '',
  disablePrimary: false,
  open: false,
  buttonLabel: 'Ok',
  cancelLabel: 'Indietro',
  onClose: () => {},
  actionToDo: () => {},
  disableActions: false,
  hasCrossCloseButton: false,
  cancelClassName:'',
  actionClassName: ''
};

/** prop types */
MyHomeSimpleModal.propTypes = {
  /** extraContentClasses: addtional classes for the dialog content */
  extraContentClasses: PropTypes.string,
  /** maxWidth: sets max-width of container (material-ui prop) */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /** dialogClasses  classes for the dialog container */
  dialogClasses: PropTypes.object,
  /** disablePrimary: condition to disable primary button */
  disablePrimary: PropTypes.bool,
  /** title: any object */
  title: PropTypes.any,
  /** buttonLabel: label for the primary button */
  buttonLabel: PropTypes.string,
  /** cancelLabel: label for the secondary button */
  cancelLabel: PropTypes.string,
  /** open: required boolean */
  open: PropTypes.bool.isRequired,
  /** onClose: required action */
  onClose: PropTypes.func.isRequired,
  /** actionToDo: additional action for the primary button */
  actionToDo: PropTypes.func,
  /** cancelAction: additional action for the secondary button. If set, the button is rendered */
  cancelAction: PropTypes.func,
  /** children: modal content */
  children: PropTypes.node,
  disableActions: PropTypes.bool,
  portalClasses: PropTypes.string,
  /** hasCrossCloseButton: show/hide cross close button */
  hasCrossCloseButton: PropTypes.bool,
  /** cancelClassName: cancel button class name */
  cancelClassName: PropTypes.string,
  /** actionClassName: action button class name */
  actionClassName: PropTypes.string,
};

export default MyHomeSimpleModal;
